<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="uikit-tooltip">
    <div class="uikit-tooltip__inner">
      <div
        ref="showListBtn"
        :data-closable="dataClosable"
        @click.stop="showListTooltip"
      >
        <slot name="default" />
      </div>
      <ul
        v-show="showBlocks[dataClosable]"
        :class="[
          'uikit-tooltip__list',
          'uikit-tooltip__list_' + listPosition,
          {'uikit-tooltip__list_fulled': isFulled },
        ]"
        :style="{
          maxHeight: maxHeight ? maxHeight + 'px' : maxHeight,
          width: listWidth,
        }"
      >
        <slot name="firstItemList" />
        <template v-if="items.length">
          <li
            v-for="(item, i) in items"
            :key="i + item.name"
            class="uikit-tooltip__item"
            :class="[item.class || '' , item.disabled && 'uikit-tooltip__item_disabled']"
            @click.stop="choose(item)"
          >
            <i
              v-if="item.iconName"
              :class="[
                'uikit-tooltip__icon',
                'bx',
                item.iconName,
                { 'uikit-tooltip__icon_right': iconPosition }
              ]"
            />
            <p class="text_s text_ellipsis">
              {{ item.name }}
            </p>
          </li>
        </template>
        <div v-else>
          <span class="text_s color_secondary">
            {{ emptyState }}
          </span>
        </div>
        <slot name="lastItemList" />
      </ul>
      <i
        v-show="show && hasTriangle"
        class="bx bxs-up-arrow uikit-tooltip__triangle"
      />
    </div>
  </div>
</template>
<script>
  import { closableMixin } from '@/mixins/closableMixin.js';

  export default {
    name: 'DefaultTooltip',
    mixins: [closableMixin],
    props: {
      items: {
        type: Array,
        default: () => [], // required нельзя ставить, иначе будет падать ошибка в консоль у зависимого компонента - DefaultTooltipBlock
      },
      listPosition: {
        type: String,
        default: 'left', // left, center, right
      },
      iconPosition: {
        type: String,
        default: '', // right
      },
      hasTriangle: {
        type: Boolean,
        default: true,
      },
      isFulled: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      maxHeight: {
        type: String,
        default: 'auto',
      },
      listWidth: {
        type: String,
        default: '',
      },
      isClosable: {
        type: Boolean,
        default: true,
      },
      show: {
        type: Boolean,
        default: false,
      },
      dataClosable: {
        type: String,
        default: '',
      },
      emptyState: {
        type: String,
        default: 'There are no items',
      },
    },
    methods: {
      choose (item) {
        if (item.disabled) return;
          this.showBlocks[this.dataClosable] = false;
          this.$emit('update:show', false);
        this.$emit('choose', item);
      },
      showListTooltip () {
        if (this.isDisabled) return;
        this.showBlocks[this.dataClosable] = !this.showBlocks[this.dataClosable];
        this.$emit('update:show', !this.showBlocks[this.dataClosable]);
      },
    },
  };
</script>
<style lang="scss">
.uikit-tooltip {

  &__inner {
    position: relative;
  }

  &__list {
    min-width: 180px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: scroll;
    position: absolute;
    z-index: 1000;
    top: calc(100% + 4px);
    padding: 8px;
    border-radius: 8px;
    background-color: var(--background-color);
    box-shadow: 0 0 5px 1px var(--tooltip-shadow);

    &_right {
      right: 0;
    }

    &_center {
      left: 50%;
      transform: translateX(-50%);
    }

    &_fulled {
      width: 100%;
    }
  }

  &__item {
    @include flex-row;
    gap: 8px;
    padding: 4px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;

    &_disabled {
      color: #00000050;
      pointer-events: none;
    }

    &:hover {
      background: var(--active-bg-cl);
    }
  }
  &__icon {
    &_right {
      order: 2;
    };
  }

  &__triangle {
    position: absolute;
    right: 10px;
    top: 20px;
    display: block;
    color: var(--background-color);
  }
}
</style>
