<template>
  <li
    class="card-emails"
    @click="isShort = !isShort"
  >
    <div class="flex flex-justify-content-between">
      <div class="flex flex-gap-8">
        <div class="flex-column flex-gap-8">
          <default-avatar
            size="xs"
            :photo-link="email.is_me ? selectedCredential.photo_link : profile.photo_link"
            :status-color="email.is_me ? profileStatusColor : ''"
          />
          <i
            v-if="!email.is_me"
            :class="[
              'bx icon_size_16',
              isStarredProfile ? 'bxs-star color_yellow' : 'bx-star color_tertiary',
            ]"
          />
        </div>
        <div>
          <div class="flex-row flex-gap-8 mb-1">
            <p class="text_s text_weight_accent">
              {{ (email.is_me ? 'You' : profile.first_name) + ': from ' + capitalize(email.provider) }}
            </p>
            <p class="text_xs color_tertiary">
              {{ '<' + email.sender + '>' }}
            </p>
          </div>
          <div class="flex-row flex-gap-4">
            <p class="text_xs color_tertiary">
              To:
            </p>
            <default-label
              size="xs"
              :label-name="email.receiver"
              custom-type="info"
            />
          </div>
          <div class="flex-row flex-gap-4">
            <p class="text_xs color_tertiary">
              Subject:
            </p>
            <p class="text_xs">
              {{ email.subject }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex-column flex-gap-4 flex-align-items-end">
        <p class="text_xs color_tertiary">
          {{ getFromNowUTC(email.created_at, true) || 'Pending' }}
        </p>
        <p :class="['text_xs', 'color_' + statusObj.color]">
          {{ statusObj.name }}
        </p>
      </div>
    </div>
    <div
      v-show="isShort"
      class="card-emails__body"
    >
      <p class="text_xs">
        {{ email.text }}
      </p>
      <default-button
        v-if="!email.is_me"
        class="flex-align-self-end"
        title="Reply"
        icon-name="bx-reply"
        color="primary"
        form="rectangle"
        size="s"
        @action="$emit('reply', email)"
      />
    </div>
  </li>
</template>
<script>
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';

  import variables from '@/styles/_variables.module.scss';
  import { mapGetters } from 'vuex';
  import { capitalizeFilterMixin } from '@/mixins/capitalizeFilterMixin';
  import { fromNowFilterMixin } from '@/mixins/fromNowFilterMixin';

  export default {
    name: 'CardEmails',
    components: {
      DefaultAvatar,
      DefaultLabel,
      DefaultButton,
    },
    mixins: [
      capitalizeFilterMixin,
      fromNowFilterMixin,
    ],
    props: {
      email: {
        type: Object,
        required: true,
      },
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isShort: false,
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      profileStatusColor () {
        return variables[`status-${this.profile.tag}`] || variables[`status-${this.profile.status}`];
      },
      isStarredProfile () {
        return this.profile.labels.some(label => label.type === 'global' && label.name === 'Starred');
      },
      statusObj () {
        switch (this.email.status) {
        case 'sent':
          return {
            name: 'Delivered',
            color: 'success',
          };
        case 'failed':
          return {
            name: 'Failed',
            color: 'danger',
          };
        default:
          return {};
        };
      },
    },
  };
</script>
<style lang="scss">
.card-emails {
  padding: 16px;
  border-radius: 8px;
  background: var(--background-color);
  box-shadow: 1px 1px 4px var(--tooltip-shadow);
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 12px var(--tooltip-shadow);
  }

  &__body {
    @include flex-column;
    gap: 16px;
    margin-top: 16px;
    padding-top: 16px;
    border-top: $border;
  }
}
</style>
