/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
export const moneyFormat = {
  methods: {
    moneyFormat (num, digits = 1) {
      const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      let item;
      if (num > 0) {
        item = lookup.slice().reverse().find((item) => num >= item.value);
      } else {
        item = lookup.slice().reverse().find((item) => num <= (item.value * -1));
      }
      return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : num;
    },
  },
};
