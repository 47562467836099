<template>
  <section class="chat">
    <div
      ref="chat"
      class="overflow-y-scroll"
      @scroll="scrollHandlerThrottle"
    >
      <div
        v-show="!loaded"
        ref="vldParent"
        class="vld-parent"
      />
      <list-messages
        v-show="areMessages"
        class="p-2"
        :messages="messages.items"
        @edit-approval="editApproval"
        @delete-message="updateMessagesOrEmails('messages', $event, true)"
      />
      <list-emails
        v-show="areEmails"
        :show-send-email-btn="!(areEmails && showBlocks.emailResponser)"
        data-excluded="emailResponser"
        class="pr-2 pl-3 pt-2 pb-2"
        :emails="emails.items"
        :profile="profile"
        @reply="replyToEmail"
      />
    </div>

    <div>
      <messages-responser
        v-show="areMessages"
        class="ml-2 mr-2 mb-2"
        :profile="profile"
        :is-disabled="isDeclinedProfile"
        @send="updateMessagesOrEmails('messages', $event)"
      />
      <emails-responser
        v-show="areEmails && showBlocks.emailResponser"
        :last-email="lastEmail"
        :profile="profile"
        class="chat__emails-responser"
        data-closable="emailResponser"
        @send-email="sendEmailHandler"
        @close="showBlocks.emailResponser = false"
      />
    </div>
  </section>
</template>
<script>
  import ListMessages from '@/components/inboxPage/chat/messages/ListMessages';
  import ListEmails from '@/components/inboxPage/chat/emails/ListEmails';
  import MessagesResponser from '@/components/inboxPage/chat/messages/responser/MessagesResponser';
  import EmailsResponser from '@/components/inboxPage/chat/emails/responser/EmailsResponser';

  import { closableMixin } from '@/mixins/closableMixin';
  import { differentFunctionsMixin } from '@/mixins/differentFunctionsMixin';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { throttle } from 'lodash';
  import { mapGetters } from 'vuex';
  import {
    getMessages,
    getEmails
  } from '@/api/profilesMethods';
  import { updateApproval } from '@/api/approvalMethods';

  export default {
    name: 'ChatInbox',
    components: {
      ListMessages,
      ListEmails,
      MessagesResponser,
      EmailsResponser,
    },
    mixins: [closableMixin, loaderMixin, differentFunctionsMixin],
    props: {
      activeMessagesGroup: {
        type: String,
        required: true,
      },
      hasOldMessagesButton: {
        type: Boolean,
        required: true,
      },
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        messages: {
          items: [],
          meta: {
            haveMoreItems: true,
          },
        },
        emails: {
          items: [],
          meta: {
            haveMoreItems: true,
          },
        },
        lastEmail: {},
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      areMessages () {
        return this.activeMessagesGroup === 'messages';
      },
      areEmails () {
        return this.activeMessagesGroup === 'emails';
      },
      isDeclinedProfile () {
        return this.profile.inmail_status !== 'accepted' ||
          this.messages.items.some(m => m.acceptance_status === 'declined');
      },
    },
    watch: {
      'profile._id' () {
        this.init();
      },
      activeMessagesGroup () {
        setTimeout(this.scrollToEnd(), 500);
      },
      'messages.meta.haveMoreItems' (value) {
        if (!value) {
          this.$emit('update:hasOldMessagesButton', true);
        }
      },
      async hasOldMessagesButton (value) {
        if (!value) {
          await this.getMessagesHandler(true, true);
        }
      },
    },
    created () {
      this.scrollHandlerThrottle = throttle(this.scrollHandler, 400, { leading: true });
    },
    async mounted () {
      this.init();
    },
    methods: {
      async init () {
        this.messages.items = this.emails.items = [];
        this.messages.meta.haveMoreItems = this.emails.meta.haveMoreItems = true;
        await Promise.all([
          this.getMessagesHandler(),
          this.getEmailsHandler(),
        ]);
        this.approval = this.profile.approval;
        if (this.approval) {
          this.approval.is_me = true;
          this.messages.items.push(this.approval);
        }
        if (!this.messages.items.length) {
          this.$emit('update:activeMessagesGroup', 'emails');
        }
        const chat = this.$refs.chat;
        if (this.messages.items.length && chat.offsetHeight === chat.scrollHeight) {
          this.messages.meta.haveMoreItems = false;
        }
        this.scrollToEnd();
      },
      async editApproval (isApprove) {
        try {
          const newApproval = await updateApproval(this.profile._id, this.selectedCredential._id, isApprove);
          newApproval.is_me = true;
          const oldApprovalIndex = this.messages.findIndex(a => a.sequence_id);
          const profile = Object.assign(this.profile, {});
          profile.approval.status = newApproval.status;
          this.$emit('update-profile-prop', profile);
          this.messages.splice(oldApprovalIndex, 1, newApproval);
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      scrollToEnd (currentScrollHeight = 0) {
        const chat = this.$refs.chat;
        chat.scrollTop = currentScrollHeight
          ? chat.scrollHeight - currentScrollHeight
          : chat.scrollHeight;
      },
      async scrollHandler () {
        const shouldSearch = this.$refs.chat.scrollTop < 50 && this[this.activeMessagesGroup].meta.haveMoreItems;
        if (!shouldSearch) {
          return;
        }
        const currentScrollHeight = this.$refs.chat.scrollHeight;
        this.activeMessagesGroup === 'messages'
          ? await this.getMessagesHandler(true, true)
          : await this.getEmailsHandler(true, true);
        this.scrollToEnd(currentScrollHeight);
      },
      async getMessagesHandler (lazyload = false, sleep = false) {
        try {
          this.loaded = false;
          const newMessages = await getMessages(
            this.profile._id,
            this.selectedCredential._id,
            !this.hasOldMessagesButton,
            lazyload ? this.messages.items.length : 0
          );
          if (sleep) { // для красоты
            await this.sleep(1000);
          }
          this.messages.items = lazyload ? [...newMessages, ...this.messages.items] : newMessages;
          this.messages.meta.haveMoreItems = !!newMessages.length;
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      async getEmailsHandler (lazyload = false, sleep = false) {
        try {
          this.loaded = false;
          const newEmails = await getEmails(
            this.profile._id,
            lazyload ? this.emails.items.length : 0
          );
          if (sleep) { // для красоты
            await this.sleep(1000);
          }
          this.emails.items = lazyload ? [...newEmails, ...this.emails.items] : newEmails;
          this.emails.meta.haveMoreItems = !!newEmails.length;
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      updateMessagesOrEmails (type, item, isDelete = false) {
        if (isDelete) {
          this[type].items = this[type].items.filter(i => i._id !== item._id);
          return;
        }
        this[type].items.push(item);
      },
      replyToEmail (email) {
        this.lastEmail = email;
        this.showBlocks.emailResponser = true;
      },
      sendEmailHandler (email) {
        this.updateMessagesOrEmails('emails', email);
        this.showBlocks.emailResponser = false;
      },

    },
  };
</script>
<style lang="scss">
@import "@/components/inboxPage/chat/inboxChat";
</style>
