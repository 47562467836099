<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    class="crm-card"
    @click="openModal"
  >
    <div class="crm-card__row">
      <div class="crm-card__info">
        <span class="crm-card__text crm-card__text_hidden text_s">{{ deal.title }}</span>
        <div class="crm-card__about-deal">
          <span class="crm-card__text text_xs color_secondary">{{ dealValue }}</span>
          <span class="crm-card__dot" />
          <div
            v-if="deal && deal.crm_profiles"
            class="crm-card__images"
          >
            <crm-avatar-item
              v-for="(profile, i) in deal.crm_profiles"
              :key="profile._id"
              :index="i"
              :name="profile.full_name"
              class="crm-card__image"
              :photo-link="profile.local_photo_link || ''"
            />
            <span
              v-if="deal.crm_profiles.length > 3"
              class="text_xs color_secondary"
            >+ {{ deal.crm_profiles.length - 3 }}</span>
          </div>
        </div>
      </div>
      <span
        class="crm-card__text text_s color_secondary"
        :class="{'color_success': deal.status === 'won',
                 'color_danger': deal.status === 'lost'}"
      >{{ dealStatus }}</span>
    </div>
    <div class="crm-card__row crm-card__row_end">
      <div class="crm-card__info">
        <span class="crm-card__text color_secondary text_xs">Owner</span>
        <img loading="lazy"
          v-if="deal.crm_owner && deal.crm_owner.img"
          class="crm-card__image"
          :src="deal.crm_owner.img"
          alt="owner-avatar"
        >
        <span
          v-else
          class="crm-card__text"
        ><i class="bx bx-user-circle color_secondary text_m" /></span>
      </div>
      <span class="crm-card__text crm-card__text_no-wrap text_xs color_secondary">
        {{ getParseDate(deal.updated_at) }}
      </span>
    </div>
  </div>
</template>
<script>
  import { moneyFormat } from '@/mixins/moneyFormat.js';
  import { setAltImg } from '@/mixins/setAltImg';
  import CrmAvatarItem from '@/components/CRMIntegration/CrmAvatarItem.vue';
  import { getParseDate } from '@/utils/workflows/utils';

  export default {
    name: 'CrmDealCard',
    components: {
      CrmAvatarItem,
    },
    mixins: [moneyFormat, setAltImg],
    props: {
      deal: {
        type: Object,
        default: null,
      },
      showStage: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
      };
    },
    computed: {
      dealValue () {
        return `${this.numberWithSpaces(this.deal.value)} ${this.deal.currency}`;
      },
      dealStatus () {
        if (this.deal.status) {
          const status = `${this.deal.status[0].toUpperCase()}${this.deal.status.slice(1)}`;
          return status;
        } else return 'Open';
      },
    },
    mounted () {
    },
    methods: {
      openModal () {
        this.$emit('open-modal-deal', this.deal);
      },
      numberWithSpaces (x) {
        const parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
      },
      getParseDate (date) {
        return getParseDate(date);
      },
    },
  };
</script>
<style lang="scss">
.crm-card {
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(10, 32, 51, 0.06), 0px 0px 8px rgba(10, 32, 51, 0.05);
  border-radius: 8px;
  background: var(--table-bg);
  display: flex;
  flex-flow: column;
  padding: 8px;
  gap: 8px;
  &:hover {
    background: var(--hover-secondary);
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &_end {
      align-items: flex-end;
    }
  }
  &__info {
    display: flex;
    flex-flow: column;
    width: 80%;
  }
  &__about-deal {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  &__images {
    display: flex;
  }
  &__image {
    border-radius: 100%;
    width: 16px;
    height: 16px;
  }
  &__profile-avatar {
    display: flex;
    align-items: center;
  }
  &__dot {
    background: var(--secondary-text-color);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 4px;
    &_hidden {
      display: block;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &_no-wrap {
      white-space: nowrap;
    }
  }
}
</style>
