<template>
  <div class="filters-block-modal">
    <div class="filters-block-modal__tabs">
      <modal-group-tab
        v-for="group in updatedFilters.data"
        :key="group.category._id"
        class="filters-block-modal__tabs-item"
        :group="group"
        :is-active="activeGroup.category._id === group.category._id"
        :has-selected-item="!!updatedFilters.chosenData[group.category._id]"
        @click.native="activeGroup = group"
      />
    </div>
    <div class="filters-block-modal__items">
      <ul class="flex-column flex-gap-8 overflow-y-scroll">
        <default-list-item
          v-for="item in activeGroup.items"
          :key="item._id"
          :title="item.name"
          :has-checkbox="true"
          :is-active-checkbox="getSelectedStatus(item._id)"
          @select="selectFilter(item._id)"
          @click.native.stop="selectFilter(item._id)"
        />
      </ul>
      <div class="flex-row flex-gap-8 flex-justify-content-end">
        <default-button
          title="Cancel"
          color="neutral"
          form="rectangle"
          size="m"
          @action="$emit('close')"
        />
        <default-button
          title="Apply"
          color="primary"
          form="rectangle"
          size="m"
          @action="apply"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import DefaultListItem from '@/components/base/uiKit/DefaultListItem';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import ModalGroupTab from '@/components/base/filtersBlock/ModalGroupTab';
  import { cloneDeep } from 'lodash';

  export default {
    name: 'FiltersBlockModal',
    components: {
      DefaultListItem,
      DefaultButton,
      ModalGroupTab,
    },
    props: {
      filters: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        activeGroup: this.filters.data[0],
        updatedFilters: cloneDeep(this.filters),
      };
    },
    methods: {
      getSelectedStatus (fieldId) {
        const categoryId = this.activeGroup.category._id;
        const category = this.updatedFilters.chosenData[categoryId];
        if (!category) {
          return false;
        }
        return category.includes(fieldId);
      },
      selectFilter (fieldId) {
        const categoryId = this.activeGroup.category._id;
        const updatedChosenData = cloneDeep(this.updatedFilters.chosenData);
        const hasChosenCategory = updatedChosenData[categoryId] !== undefined;
        if (hasChosenCategory) {
          const chosenItems = updatedChosenData[categoryId];
          const hasSelectedField = chosenItems.find(i => i === fieldId);
          updatedChosenData[categoryId] = hasSelectedField
            ? chosenItems.filter(i => i !== fieldId)
            : chosenItems.concat(fieldId);
        } else {
          updatedChosenData[categoryId] = [fieldId];
        };
        if (!updatedChosenData[categoryId].length) {
          delete updatedChosenData[categoryId];
        }
        this.updatedFilters.chosenData = updatedChosenData;
      },
      apply () {
        this.$emit('update-filters', this.updatedFilters.chosenData);
        this.$emit('close');
      },
    },
  };
</script>
<style lang="scss">
.filters-block-modal {
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
  //padding: 16px;

  &__tabs {
    border-right: $border;
    padding: 16px;
    &-item {
      width: 216px;
      gap: 4px;
    }
  }

  &__items {
    overflow: hidden;
    display: grid;
    padding: 16px;
    grid-template-rows: 1fr auto;
    //gap: 16px;
  }
}
</style>
