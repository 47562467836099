<template>
  <div
    :class="[
      'flex-column flex-gap-16 pt-25',
    ]"
  >
    <contacts-basic
      :profile="profile"
      @update-profile-prop="$emit('update-profile-prop', $event)"
    />
  </div>
</template>
<script>
  import ContactsBasic from '@/components/inboxPage/info/basic/contacts/ContactsBasic.vue';

  export default {
    name: 'ContactsInfo',
    components: {
      ContactsBasic,
    },
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
  };
</script>
