<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="step-menu">
    <div class="step-menu__container">
      <template
        v-for="button in menuButtonsFiltered"
      >
        <div
          v-if="!button.notToView.includes($route.name)"
          :key="button._id"
          class="step-menu__item"
          :class="{ 'step-menu__item_active': chosenMenu === button._id }"
          @click="chooseMenu(button._id)"
        >
          <i
            class="step-menu__icon bx"
            :class="[
              button.icon,
              { 'step-menu__icon_active': chosenMenu === button._id }
            ]"
          />
          <span
            class="step-menu__text step-menu__text_absolute color_tertiary text_xs"
            :class="{ 'color_primary': chosenMenu === button._id }"
          >{{ button.name }}</span>
        </div>
      </template>
    </div>
    <component
      :is="chosenMenu"
      :video="video"
      :template="template"
      :message="message"
      :subject="subject"
      :type="type"
      :disabled="!!template?._id"
      @add-template-to-area="addTemplateToArea"
      @add-practice="addPractice"
      @choose-video="$emit('choose-video')"
      @choose-template="$emit('choose-template')"
      @unlink-template="$emit('unlink-template')"
      @remove-video="removeVideo"
      @insert-video="insertVideo"
    />
  </div>
</template>
<script>
  import TemplatesMenu from '@/components/updateWorkflow/performAction/menuFiles/TemplatesMenu';
  import VideoMenu from '@/components/updateWorkflow/performAction/menuFiles/VideoMenu';
  import PreviewMenu from '@/components/updateWorkflow/performAction/menuFiles/PreviewMenu';
  import PlaceholdersMenu from '@/components/updateWorkflow/performAction/menuFiles/PlaceholdersMenu';
  import PracticeMenu from '@/components/updateWorkflow/performAction/menuFiles/PracticeMenu';
  import ScoreMenu from '@/components/updateWorkflow/performAction/menuFiles/ScoreMenu';
  import StatisticMenu from '@/components/updateWorkflow/performAction/menuFiles/StatisticMenu';
  import CalendlyMenu from '@/components/updateWorkflow/performAction/menuFiles/CalendlyMenu';
  import LyneMenu from '@/components/updateWorkflow/performAction/menuFiles/LyneMenu';
  export default {
    name: 'StepMenu',

    components: {
      TemplatesMenu,
      VideoMenu,
      PlaceholdersMenu,
      PreviewMenu,
      PracticeMenu,
      ScoreMenu,
      StatisticMenu,
      CalendlyMenu,
      LyneMenu,
    },
    props: {
      brand: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isTextIncludes: {
        type: Boolean,
        default: true,
      },
      video: {
        type: Object,
        default: null,
      },
      template: {
        type: Object,
        default: null,
      },
      subject: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
      message: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        menuButtons: [
          {
            icon: 'bx-code-curly',
            name: 'Placeholders',
            _id: 'PlaceholdersMenu',
            notToView: [],
          },
          // {
          //   icon: 'bx-calendar-alt',
          //   name: 'Calendly',
          //   _id: 'CalendlyMenu',
          // },
          // {
          //   icon: 'bx-bot',
          //   name: 'Lyne.ai',
          //   _id: 'LyneMenu',
          // },
          // {
          //   icon: 'bx-bar-chart',
          //   name: 'Score',
          //   _id: 'ScoreMenu',
          // },
          {
            icon: 'bx-star',
            name: 'Best practice',
            _id: 'PracticeMenu',
            notToView: ['inbox'],
          },
          {
            icon: 'bx-library',
            name: 'Templates',
            _id: 'TemplatesMenu',
            notToView: ['inbox', 'my-templates'],
          },
          {
            icon: 'bx-video',
            name: 'Video',
            _id: 'VideoMenu',
            notToView: ['inbox', 'my-templates'],
          },
          {
            icon: 'bx-file-find',
            name: 'Preview',
            _id: 'PreviewMenu',
            notToView: ['inbox'],
          },
          // {
          //   icon: 'bx-stats',
          //   name: 'Statistics',
          //   _id: 'StatisticMenu',
          // },
        ],
        placeholders: this.placeholdersToSubject,
        chosenMenu: 'PlaceholdersMenu',
      };
    },
    computed: {
      menuButtonsFiltered () {
        const menuButtonsFiltered = this.menuButtons.slice();
        if (this.$route.name !== 'my-templates') {
          if (this.disabled) {
            return menuButtonsFiltered.filter(e => e._id === 'statistics' || e._id === 'PreviewMenu');
          } else return menuButtonsFiltered;
        } else {
          return menuButtonsFiltered;
        }
      },
    },
    watch: {
      disabled () {
        this.setChosenMenu();
      },
    },
    mounted () {
      this.setChosenMenu();
    },
    methods: {
      setChosenMenu () {
        if (this.disabled && this.$route.name !== 'my-templates') {
          this.chosenMenu = 'PreviewMenu';
        } else this.chosenMenu = 'PlaceholdersMenu';
      },
      chooseMenu (button) {
        this.chosenMenu = button;
      },
      addTemplateToArea (temp, space) {
        this.$emit('add-template-to-area', temp, space);
      },
      insertVideo (video) {
        this.$emit('insert-video', video);
      },
      removeVideo (video) {
        this.$emit('remove-video', video);
      },
      addPractice (item) {
        this.$emit('add-practice', item);
      },
    },
  };
</script>
<style lang="scss">
.step-menu {
  display: flex;
  flex-flow: column;
  padding: 16px;
  gap: 16px;
  &__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__item {
      display: flex;
      flex-flow: column;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      transition: 0.1s;
      width: 80px;
    &:hover {
        opacity: 0.7;
      }
      &_active {
        cursor: initial;
        &:hover {
          opacity: 1;
        }
      }
    }
    &__icon {
      width: 32px;
      height: 32px;
      background: var(--neutral-50);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &_active {
        background: var(--primary-cl);
        color: white;
      }
    }
  }
</style>
