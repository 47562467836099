<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end">
    <modal
      :name="name"
      width="512px"
      height="500px"
      @closed="handleClose"
    >
      <div class="modal">
        <header class="modal__header">
          <p class="modal__title">
            {{ modalTitle }}
          </p>
          <i
            class="bx bx-x bx-md modal__close"
            @click="handleClose"
          />
        </header>
        <section
          v-if="scenarioList.length"
          class="modal__list"
        >
          <video-item
            v-for="video in scenarioList"
            :key="video._id"
            :video="video"
            :mode="'insert'"
            class="modal__list-item"
            @click.native="$emit('choose-video', video)"
            @insert="$emit('choose-video', video)"
          />
          <div
            class="modal__list-button-item"
            @click="openVideoEditor()"
          >
            <button
              class="primary-btn_transparent primary-btn_hovered_transparent color_primary"
            >
              <i class="bx bx-plus" />
              Create video
            </button>
          </div>
        </section>
        <section
          v-else
          class="modal__button-container"
        >
          <i class="bx bxs-video modal__icon" />
          <p class="text text_l color color_secondary mb-2">
            You don't have any recorded videos.
          </p>
          <button
            class="primary-btn_blue"
            @click="openVideoEditor()"
          >
            <i class="bx bx-plus" />
            Create new
          </button>
        </section>
      </div>
      <default-modal
        :show.sync="showVideoEditor"
        name="videoEditorModal"
        width="1200px"
        height="90%"
        :show-buttons="false"
      >
        <videos-constructor
          @close-modal="showVideoEditor = false"
        />
      </default-modal>
    </modal>
  </portal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import VideoItem from '@/components/videoEditor/VideoItem';
  import VideosConstructor from '@/views/templatesPage/videos/VideosConstructor';
  import defaultModal from '@/modals/defaultModal.vue';
  export default {
    name: 'VideosModal',
    components: {
      VideoItem,
      defaultModal,
      VideosConstructor,
    },
    props: {
      name: {
        type: String,
        default: 'VideosModal',
      },
      show: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        modalTitle: this.$store.getters['user/user'].config.name + ' Videos',
        showVideoEditor: false,
      };
    },
    computed: {
      ...mapGetters({
        scenarioList: 'scenario/scenarioList',
      }),
    },
    watch: {
      show (show) {
        show ? this.$modal.show(this.name) : this.handleClose();
      },
    },
    async created () {
      await this.$store.dispatch('scenario/SET_SCENARIO_LIST');
    },
    methods: {
      openVideoEditor () {
        this.showVideoEditor = true;
      },
      handleClose () {
        this.$emit('update:show', false);
        this.$modal.hide(this.name);
      },
    },
  };
</script>
<style lang="scss" scoped>
.modal {
  position: relative;
  &__list {
    overflow: auto;
    display: flex;
    flex-flow: column;
    gap: 8px;
    padding: 16px;
    list-style: none;
    &-item {
      flex: 1;
    }
    &-button-item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 250px;
      min-height: 68px;
      border: 1px dashed var(--secondary-text-color);
      border-radius: 16px;
      cursor: pointer;
    }
  }
  &__button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__icon {
    font-size: 48px;
    color: var(--primary-cl);
  }
}
</style>
