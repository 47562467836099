/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function updateApproval (profileId, credentialId, approve) {
  const data = await useHttp(
    `/profiles/${profileId}/approval`,
    'PUT',
    {
      approve: approve,
    },
    {
      credentials_id: credentialId,
    },
    true
  );

  return data.payload;
}

export {
  updateApproval
};
